const actions = {
  FETCH_BUILDING_CHECKLIST_INVITER: "FETCH_BUILDING_CHECKLIST_INVITER",
  FETCH_BUILDING_CHECKLIST_INVITER_SUCCESS:
    "FETCH_BUILDING_CHECKLIST_INVITER_SUCCESS",
  FETCH_BUILDING_CHECKLIST_INVITER_FAILURE:
    "FETCH_BUILDING_CHECKLIST_INVITER_FAILURE",

  ADD_BUILDING_CHECKLIST_INVITER: "ADD_BUILDING_CHECKLIST_INVITER",
  ADD_BUILDING_CHECKLIST_INVITER_SUCCESS:
    "ADD_BUILDING_CHECKLIST_INVITER_SUCCESS",
  ADD_BUILDING_CHECKLIST_INVITER_FAILURE:
    "ADD_BUILDING_CHECKLIST_INVITER_FAILURE",

  DELETE_BUILDING_CHECKLIST_INVITER: "DELETE_BUILDING_CHECKLIST_INVITER",
  DELETE_BUILDING_CHECKLIST_INVITER_SUCCESS:
    "DELETE_BUILDING_CHECKLIST_INVITER_SUCCESS",
  DELETE_BUILDING_CHECKLIST_INVITER_FAILURE:
    "DELETE_BUILDING_CHECKLIST_INVITER_FAILURE",

  RESET_BUILDING_CHECKLIST_INVITER: "RESET_BUILDING_CHECKLIST_INVITER",
};

export default actions;
