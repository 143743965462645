import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest } from "utils/axios-client";

function* getBuildingChecklistInviter(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `building/checklist-inviters?checklistId=${action.checklistId}`
      )
    );
    yield put({
      type: actions.FETCH_BUILDING_CHECKLIST_INVITER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_CHECKLIST_INVITER_FAILURE });
  }
}

function* addBuildingChecklistInviter(action) {
  try {
    yield callApi(() =>
      postRequest(
        `building/checklist-inviters?checklistId=${action.checklistId}`,
        action.payload
      )
    );
    yield put({
      type: actions.ADD_BUILDING_CHECKLIST_INVITER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_CHECKLIST_INVITER_FAILURE });
  }
}

function* deleteBuildingChecklistInviter(action) {
  try {
    yield callApi(() =>
      deleteRequest(`building/checklist-inviters?id=${action.id}`)
    );

    yield put({
      type: actions.DELETE_BUILDING_CHECKLIST_INVITER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_CHECKLIST_INVITER_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.FETCH_BUILDING_CHECKLIST_INVITER,
      getBuildingChecklistInviter
    ),
  ]);
  yield all([
    takeLatest(
      actions.ADD_BUILDING_CHECKLIST_INVITER,
      addBuildingChecklistInviter
    ),
  ]);
  yield all([
    takeLatest(
      actions.DELETE_BUILDING_CHECKLIST_INVITER,
      deleteBuildingChecklistInviter
    ),
  ]);
}
