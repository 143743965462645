import { Button } from "antd";
import React from "react";

export default function BrannBorderButton({
  children,
  size = "large",
  color = null,
  ...rest
}) {
  return (
    <Button
      className="brann-border-button"
      size={size}
      type="default"
      {...rest}
    >
      {children}
    </Button>
  );
}
