import actions from "./actions";

const initialState = {
  buildingChecklistTemplate: [],
  isFetchingBuildingChecklistTemplate: false,
  isChangedAddBuildingChecklistTemplate: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_CHECKLIST_TEMPLATE:
      return {
        ...state,
        buildingChecklistTemplate: [],
        isFetchingBuildingChecklistTemplate: true,
        isChangedAddBuildingChecklistTemplate: null,
      };
    case actions.FETCH_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        isFetchingBuildingChecklistTemplate: false,
        buildingChecklistTemplate: action.payload,
      };
    case actions.FETCH_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
      return { ...state, isFetchingBuildingChecklistTemplate: false };

    case actions.ADD_BUILDING_CHECKLIST_TEMPLATE:
      return { ...state, isChangedAddBuildingChecklistTemplate: null };
    case actions.ADD_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
      return { ...state, isChangedAddBuildingChecklistTemplate: true };
    case actions.ADD_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
      return { ...state, isChangedAddBuildingChecklistTemplate: false };

    case actions.UPDATE_BUILDING_CHECKLIST_TEMPLATE:
      return { ...state, isChangedAddBuildingChecklistTemplate: null };
    case actions.UPDATE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
      return { ...state, isChangedAddBuildingChecklistTemplate: true };
    case actions.UPDATE_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
      return { ...state, isChangedAddBuildingChecklistTemplate: false };

    case actions.DELETE_BUILDING_CHECKLIST_TEMPLATE:
      return { ...state, isChangedAddBuildingChecklistTemplate: null };
    case actions.DELETE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
      return { ...state, isChangedAddBuildingChecklistTemplate: true };
    case actions.DELETE_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
      return { ...state, isChangedAddBuildingChecklistTemplate: false };

    case actions.RESET_BUILDING_CHECKLIST_TEMPLATE:
      return { ...state, isChangedAddBuildingChecklistTemplate: null };

    default:
      return state;
  }
}

export default Reducer;
