import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Space } from "antd";
import BrannBorderButton from "components/ui/button/BorderButton";
import BrannIcon from "components/ui/typo/Icon";
import React from "react";

const AuthTypeContactButton = () => {
  return (
    <BrannBorderButton>
      <Space>
        <BrannIcon icon={faCheckCircle} />
        Fa stette
      </Space>
    </BrannBorderButton>
  );
};

export default AuthTypeContactButton;
