import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* fetchServiceCall(action) {
  try {
    const uri = action.id
      ? `workflow/service-calls/${action.id}`
      : `workflow/service-calls?page=${action.payload.page}&limit=${action.payload.limit}`;
    const response = yield callApi(() => getRequest(uri));
    const categories = yield callApi(() => getRequest("cms/workflow-categories?type=ServiceCall"));
    const responsibleCompanies = yield callApi(() => getRequest("responsible-companies?domainId=1"));
    const responsibleFollowers = yield callApi(() => getRequest("responsible-followers"));

    if (action.id) {
      yield put({
        type: actions.FETCH_SERVICE_CALL_SUCCESS,
        payload: {
          items: response.data,
          categories: categories,
          responsibleCompanies: responsibleCompanies.data,
          responsibleFollowers: responsibleFollowers.data,
          pagination: {}
        }
      });
    } else {
      yield put({
        type: actions.FETCH_SERVICE_CALL_SUCCESS,
        payload: {
          items: response.data.items,
          categories: categories.data,
          responsibleCompanies: responsibleCompanies.data,
          pagination: response.data.pagination,
        }
      });
    }
    
  } catch (error) {
    yield put({ type: actions.FETCH_SERVICE_CALL_FAILURE });
  }
}

function* createServiceCall(action) {
  try {
    const response = yield callApi(() =>
      postRequest("workflow/service-calls", action.payload)
    );
    yield put({
      type: actions.CREATE_SERVICE_CALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SERVICE_CALL_FAILURE });
  }
}

function* updateServiceCall(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`workflow/service-call?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_SERVICE_CALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_SERVICE_CALL_FAILURE });
  }
}

function* deleteServiceCall(action) {
  try {
    const response = yield callApi(() =>
      deleteRequest(`workflow/service-call?id=${action.id}`)
    );
    yield put({
      type: actions.DELETE_SERVICE_CALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_SERVICE_CALL_FAILURE });
  }
}

function* fetchServiceCallComment(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/service-calls/${action.id}/comments`,
      )
    );

    yield put({
      type: actions.FETCH_SERVICE_CALL_COMMENT_SUCCESS,
      payload: {
        serviceCallId: action.id,
        commentList: response.data.items,
      }
    });
  } catch (error) {
    yield put({ type: actions.FETCH_SERVICE_CALL_COMMENT_FAILURE });
  }
}


function* createServiceCallComment(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `workflow/service-calls/${action.id}/comments`,
        action.payload
      )
    );
    yield put({
      type: actions.CREATE_SERVICE_CALL_COMMENT_SUCCESS,
      serviceCallId: action.id,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SERVICE_CALL_COMMENT_FAILURE });
  }
}

function* updateServiceCallComment(action) {
  try {
    const response = yield callApi(() =>
      putRequest(
        `workflow/service-calls/${action.serviceCallId}/comments/${action.commentId}`,
        action.payload
      )
    );
    yield put({
      type: actions.UPDATE_SERVICE_CALL_COMMENT_SUCCESS,
      serviceCallId: action.serviceCallId,
      commentId: action.commentId,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_SERVICE_CALL_COMMENT_FAILURE });
  }
}

function* deleteServiceCallComment(action) {
  try {
    const response = yield callApi(() =>
      deleteRequest(
        `workflow/service-calls/${action.serviceCallId}/comments/${action.commentId}`,
      )
    );
    yield put({
      type: actions.DELETE_SERVICE_CALL_COMMENT_SUCCESS,
      serviceCallId: action.serviceCallId,
      commentId: action.commentId,
      payload: response
    });
  } catch (error) {
    yield put({ type: actions.DELETE_SERVICE_CALL_COMMENT_FAILURE });
  }
}

function* fetchServiceCallEmoj(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/service-calls/${action.serviceCallId}/comments/${action.commentId}/emojis`,
      )
    );

    yield put({
      type: actions.FETCH_SERVICE_CALL_EMOJ_SUCCESS,
      payload: {
        serviceCallId: action.serviceCallId,
        commentId: action.commentId,
        emojList: response.data.items,
      }
    });
  } catch (error) {
    yield put({ type: actions.FETCH_SERVICE_CALL_COMMENT_FAILURE });
  }
}

// function* createServiceCallEmoj(action) {
//   try {
//     const response = yield callApi(() =>
//       postRequest(
//         `workflow/service-calls/${action.id}/comments`,
//         action.payload
//       )
//     );
//     yield put({
//       type: actions.CREATE_SERVICE_CALL_COMMENT_SUCCESS,
//       serviceCallId: action.id,
//       payload: response.data,
//     });
//   } catch (error) {
//     yield put({ type: actions.CREATE_SERVICE_CALL_COMMENT_FAILURE });
//   }
// }

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_SERVICE_CALL, fetchServiceCall)]);
  yield all([takeLatest(actions.UPDATE_SERVICE_CALL, updateServiceCall)]);
  yield all([takeLatest(actions.DELETE_SERVICE_CALL, deleteServiceCall)]);
  yield all([takeLatest(actions.CREATE_SERVICE_CALL, createServiceCall)]);
  yield all([takeLatest(actions.FETCH_SERVICE_CALL_COMMENT, fetchServiceCallComment)]);
  yield all([
    takeLatest(actions.CREATE_SERVICE_CALL_COMMENT, createServiceCallComment),
  ]);
  yield all([
    takeLatest(actions.UPDATE_SERVICE_CALL_COMMENT, updateServiceCallComment),
  ]);
  yield all([
    takeLatest(actions.DELETE_SERVICE_CALL_COMMENT, deleteServiceCallComment),
  ]);
  yield all([
    takeLatest(actions.FETCH_SERVICE_CALL_EMOJ, fetchServiceCallEmoj),
  ]);
}
