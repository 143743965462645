const actions = {
  FETCH_WORKFLOW_FREQUENCY: "FETCH_WORKFLOW_FREQUENCY",
  FETCH_WORKFLOW_FREQUENCY_SUCCESS: "FETCH_WORKFLOW_FREQUENCY_SUCCESS",
  FETCH_WORKFLOW_FREQUENCY_FAILURE: "FETCH_WORKFLOW_FREQUENCY_FAILURE",

  ADD_WORKFLOW_FREQUENCY: "ADD_WORKFLOW_FREQUENCY",
  ADD_WORKFLOW_FREQUENCY_SUCCESS: "ADD_WORKFLOW_FREQUENCY_SUCCESS",
  ADD_WORKFLOW_FREQUENCY_FAILURE: "ADD_WORKFLOW_FREQUENCY_FAILURE",

  UPDATE_WORKFLOW_FREQUENCY: "UPDATE_WORKFLOW_FREQUENCY",
  UPDATE_WORKFLOW_FREQUENCY_SUCCESS: "UPDATE_WORKFLOW_FREQUENCY_SUCCESS",
  UPDATE_WORKFLOW_FREQUENCY_FAILURE: "UPDATE_WORKFLOW_FREQUENCY_FAILURE",

  DELETE_WORKFLOW_FREQUENCY: "DELETE_WORKFLOW_FREQUENCY",
  DELETE_WORKFLOW_FREQUENCY_SUCCESS: "DELETE_WORKFLOW_FREQUENCY_SUCCESS",
  DELETE_WORKFLOW_FREQUENCY_FAILURE: "DELETE_WORKFLOW_FREQUENCY_FAILURE",

  RESET_WORKFLOW_FREQUENCY: "RESET_WORKFLOW_FREQUENCY",
};

export default actions;
