import actions from "./actions";

const initialState = {
  serviceCall: [],
  serviceCallCategories: [],
  serviceCallResponsibleCompanies: [],
  serviceCallResponsibleFollowers: [],
  serviceCallPagination: {},
  serviceCallComment: [],
  isFetchingServiceCall: false,
  isFetchingServiceCallComment: false,
  isChangedServiceCall: null,
  isChangedServiceCallComment: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
        isFetchingServiceCall: true,
      };
    case actions.FETCH_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        isFetchingServiceCall: false,
        serviceCall: action.payload.items,
        serviceCallCategories: action.payload.categories,
        serviceCallPagination: action.payload.pagination,
        serviceCallResponsibleCompanies: action.payload.responsibleCompanies,
      };
    case actions.FETCH_SERVICE_CALL_FAILURE:
      return { ...state, isFetchingServiceCall: false };

    case actions.CREATE_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
      };
    case actions.CREATE_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        isChangedServiceCall: true,
      };
    case actions.CREATE_SERVICE_CALL_FAILURE:
      return { ...state, isChangedServiceCall: false };

    case actions.UPDATE_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
      };
    case actions.UPDATE_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        serviceCall: action.payload,
        isChangedServiceCall: true,
      };
    case actions.UPDATE_SERVICE_CALL_FAILURE:
      return { ...state, isChangedServiceCall: false };

    case actions.DELETE_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
      };
    case actions.DELETE_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        isChangedServiceCall: true,
      };
    case actions.DELETE_SERVICE_CALL_FAILURE:
      return { ...state, isChangedServiceCall: false };

    case actions.FETCH_SERVICE_CALL_COMMENT:
      return { ...state, isFetchingServiceCallComment: false };

    case actions.FETCH_SERVICE_CALL_COMMENT_SUCCESS:
      let newList = [action.payload, ...state.serviceCallComment];
      return {
        ...state,
        isFetchingServiceCallComment: true,
        serviceCallComment: newList,
      }

    case actions.FETCH_SERVICE_CALL_COMMENT_FAILURE:
      return {
        ...state,
        isFetchingServiceCallComment: false,
      }

    case actions.CREATE_SERVICE_CALL_COMMENT:
      return {
        ...state,
        isChangedServiceCallComment: null,
      };
    case actions.CREATE_SERVICE_CALL_COMMENT_SUCCESS:
      const updatedComments = state.serviceCallComment.map(comment => 
        comment.serviceCallId === action.serviceCallId ? { ...comment, commentList: [...comment.commentList, action.payload] } : comment
      );
      return {
        ...state,
        isChangedServiceCallComment: true,
        serviceCallComment: updatedComments,
      };
    case actions.CREATE_SERVICE_CALL_COMMENT_FAILURE:
      return { ...state, isChangedServiceCallComment: false };

    case actions.UPDATE_SERVICE_CALL_COMMENT:
      return {
        ...state,
        isChangedServiceCallComment: null,
      };
    case actions.UPDATE_SERVICE_CALL_COMMENT_SUCCESS:
      const __updatedComments = state.serviceCallComment.map(comment => 
        comment.serviceCallId === action.serviceCallId ? { ...comment, commentList: comment.commentList.map(
          item => (item.id === action.commentId && item.serviceCallId === action.serviceCallId) ? { ...item, comment: action.payload.comment} : item ) } : comment
      );
      return {
        ...state,
        serviceCallComment: __updatedComments,
        isChangedServiceCallComment: true,
      };
    case actions.UPDATE_SERVICE_CALL_COMMENT_FAILURE:
      return { ...state, isChangedServiceCallComment: false };

    case actions.DELETE_SERVICE_CALL_COMMENT:
      return {
        ...state,
        isChangedServiceCallComment: null,
      };
    case actions.DELETE_SERVICE_CALL_COMMENT_SUCCESS:
      const _updatedComments = state.serviceCallComment.map(comment => 
        comment.serviceCallId === action.serviceCallId ? { ...comment, commentList: comment.commentList.filter(item => item.id !== action.commentId) } : comment
      );
      return {
        ...state,
        serviceCallComment: _updatedComments,
        isChangedServiceCallComment: true,
      };
    case actions.DELETE_SERVICE_CALL_COMMENT_FAILURE:
      return { ...state, isChangedServiceCallComment: false };

    default:
      return state;
  }
}

export default Reducer;
