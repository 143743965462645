import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* fetchUserGroup(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        action.id ? `cms/user-group?id=${action.id}` : "cms/user-group"
      )
    );
    yield put({
      type: actions.FETCH_USER_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_USER_GROUP_FAILURE });
  }
}

function* createUserGroup(action) {
  try {
    const response = yield callApi(() =>
      postRequest("cms/user-group", action.payload)
    );
    yield put({
      type: actions.CREATE_USER_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_USER_GROUP_FAILURE });
  }
}

function* updateUserGroup(action) {
  try {
    const response = yield callApi(() =>
      putRequest("cms/user-group", action.payload)
    );
    yield put({
      type: actions.UPDATE_USER_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_GROUP_FAILURE });
  }
}

function* deleteUserGroup(action) {
  try {
    const response = yield callApi(() =>
      deleteRequest(`cms/user-group?id=${action.payload}`)
    );
    yield put({
      type: actions.DELETE_USER_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_USER_GROUP_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_USER_GROUP, fetchUserGroup)]);
  yield all([takeLatest(actions.UPDATE_USER_GROUP, updateUserGroup)]);
  yield all([takeLatest(actions.DELETE_USER_GROUP, deleteUserGroup)]);
  yield all([takeLatest(actions.CREATE_USER_GROUP, createUserGroup)]);
}
