import actions from "./actions";

const initialState = {
  workflowFrequency: [],
  isFetchingWorkflowFrequency: false,
  isChangedAddWorkflowFrequency: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_WORKFLOW_FREQUENCY:
      return {
        ...state,
        workflowFrequency: [],
        isFetchingWorkflowFrequency: true,
        isChangedAddWorkflowFrequency: null,
      };
    case actions.FETCH_WORKFLOW_FREQUENCY_SUCCESS:
      return {
        ...state,
        isFetchingWorkflowFrequency: false,
        workflowFrequency: action.payload,
      };
    case actions.FETCH_WORKFLOW_FREQUENCY_FAILURE:
      return { ...state, isFetchingWorkflowFrequency: false };

    case actions.ADD_WORKFLOW_FREQUENCY:
      return { ...state, isChangedAddWorkflowFrequency: null };
    case actions.ADD_WORKFLOW_FREQUENCY_SUCCESS:
      return { ...state, isChangedAddWorkflowFrequency: true };
    case actions.ADD_WORKFLOW_FREQUENCY_FAILURE:
      return { ...state, isChangedAddWorkflowFrequency: false };

    case actions.UPDATE_WORKFLOW_FREQUENCY:
      return { ...state, isChangedAddWorkflowFrequency: null };
    case actions.UPDATE_WORKFLOW_FREQUENCY_SUCCESS:
      return { ...state, isChangedAddWorkflowFrequency: true };
    case actions.UPDATE_WORKFLOW_FREQUENCY_FAILURE:
      return { ...state, isChangedAddWorkflowFrequency: false };

    case actions.DELETE_WORKFLOW_FREQUENCY:
      return { ...state, isChangedAddWorkflowFrequency: null };
    case actions.DELETE_WORKFLOW_FREQUENCY_SUCCESS:
      return { ...state, isChangedAddWorkflowFrequency: true };
    case actions.DELETE_WORKFLOW_FREQUENCY_FAILURE:
      return { ...state, isChangedAddWorkflowFrequency: false };

    case actions.RESET_WORKFLOW_FREQUENCY:
      return { ...state, isChangedAddWorkflowFrequency: null };

    default:
      return state;
  }
}

export default Reducer;
