import BrannText from "components/ui/typo/Text";
import BrannTitle from "components/ui/typo/Title";
import RegistrationLayout from "layouts/Registration";
import React from "react";

const Page403 = () => {
  return (
    <>
      <RegistrationLayout>
        <BrannTitle text={"403 Forbidden Page"} />
        <BrannText
          text={
            "Please wait till admin approve. If you have urgent thing, please contact us"
          }
        />
      </RegistrationLayout>
    </>
  );
};

export default Page403;
