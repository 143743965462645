import actions from "./actions";

const initialState = {
  userGroup: [],
  isFecthingUserGroup: false,

  isChangedUserGroup: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_USER_GROUP:
      return {
        ...state,
        isChangedUserGroup: null,
        isFecthingUserGroup: true,
      };
    case actions.FETCH_USER_GROUP_SUCCESS:
      return {
        ...state,
        isFecthingUserGroup: false,
        userGroup: action.payload,
      };
    case actions.FETCH_USER_GROUP_FAILURE:
      return { ...state, isFecthingUserGroup: false };

    case actions.CREATE_USER_GROUP:
      return {
        ...state,
        isChangedUserGroup: null,
      };
    case actions.CREATE_USER_GROUP_SUCCESS:
      return {
        ...state,
        isChangedUserGroup: true,
      };
    case actions.CREATE_USER_GROUP_FAILURE:
      return { ...state, isChangedUserGroup: false };

    case actions.UPDATE_USER_GROUP:
      return {
        ...state,
        isChangedUserGroup: null,
      };
    case actions.UPDATE_USER_GROUP_SUCCESS:
      return {
        ...state,
        isChangedUserGroup: true,
      };
    case actions.UPDATE_USER_GROUP_FAILURE:
      return { ...state, isChangedUserGroup: false };

    case actions.DELETE_USER_GROUP:
      return {
        ...state,
        isChangedUserGroup: null,
      };
    case actions.DELETE_USER_GROUP_SUCCESS:
      return {
        ...state,
        isChangedUserGroup: true,
      };
    case actions.DELETE_USER_GROUP_FAILURE:
      return { ...state, isChangedUserGroup: false };

    default:
      return state;
  }
}

export default Reducer;
