import React from "react";

import { Avatar, Col, Dropdown, Image, Layout, Row, Space } from "antd";
import LogoImage from "assets/images/logo.png";
import AuthTypeContactButton from "components/auth/base/ContactButton";
import BrannIcon from "components/ui/typo/Icon";
import { faChevronDown, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { t } from "i18next";

const { Content } = Layout;

const RegistrationLayout = ({ step, children }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let firstName;
  let lastName;
  if (localStorage.getItem("user")) {
    firstName = JSON.parse(localStorage.getItem("user")).firstName;
    lastName = JSON.parse(localStorage.getItem("user")).lastName;
  } else {
    return <Navigate to={"/auth/login"} />;
  }
  const onClickMenuItem = (key) => {
    if (key === "/logout") {
      dispatch({
        type: "LOGOUT",
      });
      navigate("/auth/login");
    }
  };

  const items = [
    {
      key: "/logout",
      icon: <BrannIcon icon={faSignOut} size={16} />,
      label: t("settings.logout"),
      onClick: () => onClickMenuItem("/logout"),
    },
  ];

  return (
    <Layout className="app-layout">
      <Row
        justify={"space-between"}
        align={"middle"}
        style={{ padding: "24px 48px" }}
      >
        <Col flex={"auto"}>
          <Image
            src={LogoImage}
            width={120}
            preview={false}
            draggable={false}
          />
        </Col>
        <Col flex={"150px"}>
          <AuthTypeContactButton />
        </Col>
        <Col flex={"0px"}>
          <Dropdown
            menu={{ items }}
            overlayClassName="header-menu"
            trigger={["click"]}
            arrow
          >
            <Space>
              <Avatar>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Avatar>
              <BrannIcon icon={faChevronDown} />
            </Space>
          </Dropdown>
        </Col>
      </Row>
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default RegistrationLayout;
