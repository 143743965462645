import actions from "./actions";

const initialState = {
  buildingChecklistInviter: [],
  isFetchingBuildingChecklistInviter: false,
  isChangedAddBuildingChecklistInviter: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_CHECKLIST_INVITER:
      return {
        ...state,
        buildingChecklistInviter: [],
        isFetchingBuildingChecklistInviter: true,
        isChangedAddBuildingChecklistInviter: null,
      };
    case actions.FETCH_BUILDING_CHECKLIST_INVITER_SUCCESS:
      return {
        ...state,
        isFetchingBuildingChecklistInviter: false,
        buildingChecklistInviter: action.payload,
      };
    case actions.FETCH_BUILDING_CHECKLIST_INVITER_FAILURE:
      return { ...state, isFetchingBuildingChecklistInviter: false };

    case actions.ADD_BUILDING_CHECKLIST_INVITER:
      return { ...state, isChangedAddBuildingChecklistInviter: null };
    case actions.ADD_BUILDING_CHECKLIST_INVITER_SUCCESS:
      return { ...state, isChangedAddBuildingChecklistInviter: true };
    case actions.ADD_BUILDING_CHECKLIST_INVITER_FAILURE:
      return { ...state, isChangedAddBuildingChecklistInviter: false };

    case actions.DELETE_BUILDING_CHECKLIST_INVITER:
      return { ...state, isChangedAddBuildingChecklistInviter: null };
    case actions.DELETE_BUILDING_CHECKLIST_INVITER_SUCCESS:
      return { ...state, isChangedAddBuildingChecklistInviter: true };
    case actions.DELETE_BUILDING_CHECKLIST_INVITER_FAILURE:
      return { ...state, isChangedAddBuildingChecklistInviter: false };

    case actions.RESET_BUILDING_CHECKLIST_INVITER:
      return { ...state, isChangedAddBuildingChecklistInviter: null };

    default:
      return state;
  }
}

export default Reducer;
