import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/axios-client";
import isEmptyObject from "utils/isEmptyObject";

function* fetchTasksWithRelatedData() {
  try {
    const responseTasks = yield callApi(() =>
      getRequest(`workflow/tasks?&limit=${10}&page=${1}`)
    );
    const responseBuildings = yield callApi(() => getRequest("building"));
    const responseCustomers = yield callApi(() => getRequest("crm/customer"));
    const frequencies = yield callApi(() => getRequest("workflow/frequencies"));
    const categories = yield callApi(() =>
      getRequest("cms/workflow-categories?type=Task")
    );
    const responsibleCompanies = yield callApi(() =>
      getRequest("responsible-companies")
    );
    //
    const responsibleFollowers = yield callApi(() =>
      getRequest("responsible-followers")
    );
    const mainThemes = yield callApi(() => getRequest("cms/main-theme"));
    yield put({
      type: actions.FETCH_TASK_TASKS_WITH_RELATED_DATA_SUCCESS,
      payload: responseTasks.data,
      payload_buildings: responseBuildings.data,
      payload_customers: responseCustomers.data,
      payload_frequencies: frequencies.data,
      payload_categories: categories.data,
      payload_responsibleCompanies: responsibleCompanies.data,
      payload_responsibleFollowers: responsibleFollowers.data,
      payload_mainThemes: mainThemes.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_TASKS_WITH_RELATED_DATA_FAILURE });
  }
}

function* fetchTasks(action) {
  try {
    const { status, buildingId, limit, page, responsibleExecutorId } =
      action.payload;
    const responseTasks = yield callApi(() =>
      getRequest(
        `workflow/tasks?status=${status}&buildingId=${buildingId}&limit=${limit}&page=${page}&responsibleExecutorId=${responsibleExecutorId}`
      )
    );
    yield put({
      type: actions.FETCH_TASK_TASKS_SUCCESS,
      payload: responseTasks.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_TASKS_FAILURE });
  }
}

function* fetchTaskResponsibleExecutorsByCompanyId(action) {
  try {
    const responsibleExecutors = yield callApi(() =>
      getRequest(`responsible-executors?companyId=${action.payload}`)
    );
    yield put({
      type: actions.FETCH_TASK_RESPONSIBLE_EXECUTORS_SUCCESS,
      payload: responsibleExecutors.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_RESPONSIBLE_EXECUTORS_FAILURE });
  }
}

function* fetchTaskTemplateByBuildingId(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`workflow/task-templates?buildingId=${action.buildingId}`)
    );
    yield put({
      type: actions.FETCH_TASK_TEMPLATE_BY_BUILDING_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_TEMPLATE_BY_BUILDING_ID_FAILURE });
  }
}

function* fetchTaskById(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`workflow/tasks?taskId=${action.id}`, action.payload)
    );
    yield put({
      type: actions.FETCH_TASK_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_BY_ID_FAILURE });
  }
}

function* deleteTask(action) {
  try {
    yield callApi(() => deleteRequest(`workflow/tasks?id=${action.taskId}`));
    yield put({ type: actions.DELETE_TASK_SUCCESS });
  } catch (error) {
    yield put({ type: actions.DELETE_TASK_FAILURE });
  }
}

function* fetchTaskSatistics(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/task/statistics?startedAt=${action.startDate}&endedAt=${action.endDate}&type=${action.fetchType}`,
        action.payload
      )
    );
    yield put({
      type: actions.FETCH_TASK_SATISTICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_SATISTICS_FAILURE });
  }
}

function* fetchTaskSatisticsByDuedate(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/task/statistics?startedAt=${action.startDate}&endedAt=${action.endDate}&type=duedate`,
        action.payload
      )
    );
    yield put({
      type: actions.FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE });
  }
}

function* fetchBuildings() {
  try {
    const response = yield callApi(() => getRequest("building"));
    yield put({
      type: actions.FETCH_BUILDINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDINGS_FAILURE });
  }
}

function* addTasks(action) {
  try {
    const response = yield callApi(() =>
      postRequest("workflow/tasks", action.payload)
    );
    yield put({
      type: actions.ADD_TASK_TASKS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_TASK_TASKS_FAILURE });
  }
}

function* updateTasks(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`workflow/tasks?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_TASK_TASKS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_TASK_TASKS_FAILURE });
  }
}

function* addTaskComment(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `workflow/task-comments?taskId=${action.taskId}`,
        action.payload
      )
    );
    yield put({
      type: actions.ADD_TASK_COMMENT_SUCCESS,
      payload: response.data,
    });
    if (!isEmptyObject(action.newViewer) && action.payload.parentId !== 0) {
      yield callApi(() =>
        putRequest(
          `workflow/task-comments?id=${action.payload.parentId}&taskId=${action.taskId}&updateViewer=true`,
          { ...action.newViewer }
        )
      );
    }
  } catch (error) {
    yield put({ type: actions.ADD_TASK_COMMENT_FAILURE });
  }
}

function* updateTaskComment(action) {
  try {
    yield callApi(() =>
      putRequest(
        `workflow/task-comments?id=${action.id}&taskId=${action.taskId}`,
        action.payload
      )
    );
    yield put({
      type: actions.UPDATE_TASK_COMMENT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_TASK_COMMENT_FAILURE });
  }
}

function* updateTaskCommentEmoji(action) {
  try {
    yield callApi(() =>
      putRequest(
        `workflow/task-comments?id=${action.id}&taskId=${action.taskId}&updateViewer=true`,
        action.payload
      )
    );
  } catch (error) {
    yield put({ type: actions.UPDATE_TASK_COMMENT_FAILURE });
  }
}
function* deleteTaskComment(action) {
  try {
    yield callApi(() =>
      deleteRequest(`workflow/task-comments?id=${action.id}`, action.payload)
    );
    yield put({ type: actions.DELETE_TASK_COMMENT_SUCCESS });
  } catch (error) {
    yield put({ type: actions.DELETE_TASK_COMMENT_FAILURE });
  }
}

function* addTaskToDo(action) {
  try {
    const response = yield callApi(() =>
      postRequest(`workflow/task-todos?taskId=${action.taskId}`, action.payload)
    );
    yield put({
      type: actions.ADD_TASK_TO_DO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_TASK_TO_DO_FAILURE });
  }
}

function* updateTaskToDo(action) {
  try {
    yield callApi(() =>
      putRequest(`workflow/task-todos?id=${action.id}`, action.payload)
    );
    // yield put({ type: actions.UPDATE_TASK_TO_DO_SUCCESS });
  } catch (error) {
    yield put({ type: actions.UPDATE_TASK_TO_DO_FAILURE });
  }
}

function* deleteTaskToDo(action) {
  try {
    yield callApi(() =>
      deleteRequest(`workflow/task-todos?id=${action.id}`, action.payload)
    );
    // yield put({ type: actions.DELETE_TASK_TO_DO_SUCCESS });
  } catch (error) {
    yield put({ type: actions.DELETE_TASK_TO_DO_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_TASK_TASKS, fetchTasks)]);
  yield all([
    takeLatest(
      actions.FETCH_TASK_TASKS_WITH_RELATED_DATA,
      fetchTasksWithRelatedData
    ),
  ]);
  yield all([takeLatest(actions.FETCH_TASK_BY_ID, fetchTaskById)]);
  yield all([takeLatest(actions.FETCH_TASK_SATISTICS, fetchTaskSatistics)]);
  yield all([
    takeLatest(
      actions.FETCH_TASK_SATISTICS_BY_DUEDATE,
      fetchTaskSatisticsByDuedate
    ),
  ]);
  yield all([takeLatest(actions.FETCH_BUILDINGS, fetchBuildings)]);
  yield all([takeLatest(actions.ADD_TASK_TASKS, addTasks)]);
  yield all([takeLatest(actions.UPDATE_TASK_TASKS, updateTasks)]);
  yield all([
    takeLatest(
      actions.FETCH_TASK_RESPONSIBLE_EXECUTORS,
      fetchTaskResponsibleExecutorsByCompanyId
    ),
  ]);
  yield all([takeLatest(actions.ADD_TASK_COMMENT, addTaskComment)]);
  yield all([takeLatest(actions.UPDATE_TASK_COMMENT, updateTaskComment)]);
  yield all([takeLatest(actions.DELETE_TASK_COMMENT, deleteTaskComment)]);
  yield all([takeLatest(actions.ADD_TASK_TO_DO, addTaskToDo)]);
  yield all([takeLatest(actions.UPDATE_TASK_TO_DO, updateTaskToDo)]);
  yield all([takeLatest(actions.DELETE_TASK_TO_DO, deleteTaskToDo)]);
  yield all([
    takeLatest(
      actions.FETCH_TASK_TEMPLATE_BY_BUILDING_ID,
      fetchTaskTemplateByBuildingId
    ),
  ]);
  yield all([takeLatest(actions.DELETE_TASK, deleteTask)]);
  yield all([
    takeLatest(actions.UPDATE_TASK_COMMENT_EMOJI, updateTaskCommentEmoji),
  ]);
}
