import React from "react";
import { Typography } from "antd";
import { useMobileViewport } from "utils/responsive";

const { Title } = Typography;

export default function BrannTitle({ text, align, color }) {
  const isMobileView = useMobileViewport();

  return (
    <Title
      level={isMobileView ? 3 : 2}
      style={{ marginTop: 0, textAlign: align, color: color }}
    >
      {text}
    </Title>
  );
}
