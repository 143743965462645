import React, { useCallback } from "react";
import { Typography } from "antd";
import { useMobileViewport } from "utils/responsive";

const { Text } = Typography;

export default function BrannText({
  text,
  align,
  color,
  size,
  marginLeft,
  marginTop = 0,
  vericalAlign,
  height,
  lineHeight,
  display,
  backgroundColor,
  width,
  padding,
  weight,
  ellipsis = false,
  ...rest
}) {
  const isMobileView = useMobileViewport();

  const fontSize = useCallback(() => {
    return isMobileView ? 12 : 16;
  }, [isMobileView]);

  return (
    <Text
      ellipsis={ellipsis}
      {...rest}
      style={{
        textAlign: align,
        color: color,
        fontSize: size ? size : fontSize(),
        marginLeft: marginLeft,
        marginTop: marginTop,
        verticalAlign: vericalAlign,
        height: height,
        lineHeight: lineHeight,
        display: display,
        backgroundColor: backgroundColor,
        width: width,
        padding: padding,
        fontWeight: weight,
      }}
    >
      {text}
    </Text>
  );
}
