import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getWorkflowFrequency() {
  try {
    const response = yield callApi(() => getRequest(`workflow/frequencies`));
    yield put({
      type: actions.FETCH_WORKFLOW_FREQUENCY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_WORKFLOW_FREQUENCY_FAILURE });
  }
}

function* addWorkflowFrequency(action) {
  try {
    yield callApi(() => postRequest(`workflow/frequencies`, action.payload));
    yield put({
      type: actions.ADD_WORKFLOW_FREQUENCY_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_WORKFLOW_FREQUENCY_FAILURE });
  }
}

function* editWorkflowFrequency(action) {
  try {
    yield callApi(() =>
      putRequest(`workflow/frequencies?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_WORKFLOW_FREQUENCY_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_WORKFLOW_FREQUENCY_FAILURE });
  }
}

function* deleteWorkflowFrequency(action) {
  try {
    yield callApi(() => deleteRequest(`workflow/frequencies?id=${action.id}`));

    yield put({
      type: actions.DELETE_WORKFLOW_FREQUENCY_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_WORKFLOW_FREQUENCY_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_WORKFLOW_FREQUENCY, getWorkflowFrequency),
  ]);
  yield all([takeLatest(actions.ADD_WORKFLOW_FREQUENCY, addWorkflowFrequency)]);
  yield all([
    takeLatest(actions.UPDATE_WORKFLOW_FREQUENCY, editWorkflowFrequency),
  ]);
  yield all([
    takeLatest(actions.DELETE_WORKFLOW_FREQUENCY, deleteWorkflowFrequency),
  ]);
}
