import actions from "./actions";

const initialState = {
  taskForComment: {},
  taskComment: {},
  isAddTaskComment: null,
  isUpdateTaskComment: null,
  isDeleteTaskComment: null,
  tasks: [],
  taskStatistics: [],
  taskStatisticsByDuedate: [],
  task_buildings: [],
  task_customers: [],
  isFetchingTaskComment: false,
  isFetchingTaskSatistics: false,
  isFetchingTaskSatisticsByDuedate: false,
  isFetchingTasks: false,
  isFetchingBuildings: false,
  isFetchingTasksWithRelatedData: false,
  createTask: null,
  isChangedTask: null,
  taskById: null,
  frequencies: [],
  categories: [],
  responsibleCompanies: [],
  responsibleExecutors: [],
  isFetchingTaskResponsibleExecutors: false,
  taskTemplateByBuildingId: [],
  isFetchingTaskTemplateByBuildingId: false,
  responsibleFollowers: [],
  mainThemes: [],
  newTodoItem: {},
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TASK_TASKS:
      return {
        ...state,
        isChangedTask: null,
        isFetchingTasks: true,
      };
    case actions.FETCH_TASK_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingTasks: false,
        tasks: action.payload,
      };
    case actions.FETCH_TASK_TASKS_FAILURE:
      return { ...state, isFetchingTasks: false };

    case actions.FETCH_TASK_TASKS_WITH_RELATED_DATA:
      return {
        ...state,
        isChangedTask: null,
        isFetchingTasksWithRelatedData: true,
      };
    case actions.FETCH_TASK_TASKS_WITH_RELATED_DATA_SUCCESS:
      return {
        ...state,
        isFetchingTasksWithRelatedData: false,
        tasks: action.payload,
        task_buildings: action.payload_buildings,
        task_customers: action.payload_customers,
        frequencies: action.payload_frequencies,
        categories: action.payload_categories,
        responsibleCompanies: action.payload_responsibleCompanies,
        responsibleFollowers: action.payload_responsibleFollowers,
        mainThemes: action.payload_mainThemes,
      };
    case actions.FETCH_TASK_TASKS_WITH_RELATED_DATA_FAILURE:
      return { ...state, isFetchingTasksWithRelatedData: false };

    case actions.FETCH_TASK_SATISTICS:
      return {
        ...state,
        isFetchingTaskSatistics: true,
      };
    case actions.FETCH_TASK_SATISTICS_SUCCESS:
      return {
        ...state,
        isFetchingTaskSatistics: false,
        taskStatistics: action.payload,
      };
    case actions.FETCH_TASK_SATISTICS_FAILURE:
      return { ...state, isFetchingTaskSatistics: false };

    case actions.FETCH_TASK_SATISTICS_BY_DUEDATE:
      return {
        ...state,
        isFetchingTaskSatisticsByDuedate: true,
      };
    case actions.FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS:
      return {
        ...state,
        isFetchingTaskSatisticsByDuedate: false,
        taskStatisticsByDuedate: action.payload,
      };
    case actions.FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE:
      return { ...state, isFetchingTaskSatisticsByDuedate: false };

    case actions.ADD_TASK_TASKS:
      return {
        ...state,
        createTask: null,
        isChangedTask: null,
      };
    case actions.ADD_TASK_TASKS_SUCCESS:
      return {
        ...state,
        isChangedTask: true,
        createTask: action.payload,
      };
    case actions.ADD_TASK_TASKS_FAILURE:
      return { ...state, isChangedTask: false };

    case actions.UPDATE_TASK_TASKS:
      return {
        ...state,
        isChangedTask: null,
      };
    case actions.UPDATE_TASK_TASKS_SUCCESS:
      return {
        ...state,
        isChangedTask: true,
        createTask: action.payload,
      };
    case actions.UPDATE_TASK_TASKS_FAILURE:
      return { ...state, isChangedTask: false };

    case actions.RESET_TASK:
      return {
        ...state,
        createTask: { id: 1 },
      };

    case actions.FETCH_TASK_BY_ID:
      return {
        ...state,
        isFetchingTasks: true,
        taskById: null,
      };

    case actions.FETCH_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        isFetchingTasks: false,
        taskById: action.payload,
      };

    case actions.FETCH_TASK_BY_ID_FAILURE:
      return { ...state, isFetchingTasks: false };

    case actions.FETCH_TASK_RESPONSIBLE_EXECUTORS:
      return {
        ...state,
        isFetchingTaskResponsibleExecutors: true,
      };
    case actions.FETCH_TASK_RESPONSIBLE_EXECUTORS_SUCCESS:
      return {
        ...state,
        isFetchingTaskResponsibleExecutors: false,
        responsibleExecutors: action.payload,
      };
    case actions.FETCH_TASK_TEMPLATE_BY_BUILDING_ID:
      return {
        ...state,
        isFetchingTaskTemplateByBuildingId: true,
      };
    case actions.FETCH_TASK_RESPONSIBLE_EXECUTORS_FAILURE:
      return { ...state, isFetchingTaskResponsibleExecutors: false };
    case actions.FETCH_TASK_TEMPLATE_BY_BUILDING_ID_SUCCESS:
      return {
        ...state,
        isFetchingTaskTemplateByBuildingId: false,
        taskTemplateByBuildingId: action.payload,
      };
    case actions.FETCH_TASK_TEMPLATE_BY_BUILDING_ID_FAILURE:
      return { ...state, isFetchingTaskTemplateByBuildingId: false };
    case actions.ADD_TASK_COMMENT:
      return {
        ...state,
        isAddTaskComment: null,
        taskComment: {},
      };
    case actions.ADD_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        isAddTaskComment: true,
        taskComment: action.payload,
      };
    case actions.ADD_TASK_COMMENT_FAILURE:
      return { ...state, isAddTaskComment: false };

    case actions.UPDATE_TASK_COMMENT:
      return {
        ...state,
        isUpdateTaskComment: null,
      };
    case actions.UPDATE_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        isUpdateTaskComment: true,
      };
    case actions.UPDATE_TASK_COMMENT_FAILURE:
      return { ...state, isUpdateTaskComment: false };
    
    case actions.CHANGE_ISUPDATING_STATUS:
      return {
        ...state,
        isUpdateTaskComment: null,
      }

    case actions.DELETE_TASK_COMMENT:
      return {
        ...state,
        isDeleteTaskComment: null,
      };
    case actions.DELETE_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        isDeleteTaskComment: true,
      };
    case actions.DELETE_TASK_COMMENT_FAILURE:
      return { ...state, isDeleteTaskComment: false };

    case actions.ADD_TASK_TO_DO:
      return {
        ...state,
        newTodoItem : {},
      };
    case actions.ADD_TASK_TO_DO_SUCCESS:
      return {
        ...state,
        newTodoItem : action.payload,
      };
    case actions.ADD_TASK_TO_DO_FAILURE:
      return { ...state, newTodoItem : {}};

    case actions.UPDATE_TASK_TO_DO:
      return {
        ...state,
        isChangedTask: null,
      };
    case actions.UPDATE_TASK_TO_DO_SUCCESS:
      return {
        ...state,
        isChangedTask: true,
      };
    case actions.UPDATE_TASK_TO_DO_FAILURE:
      return { ...state, isChangedTask: false};

    case actions.DELETE_TASK_TO_DO:
      return {
        ...state,
        isChangedTask: null,

      };
    case actions.DELETE_TASK_TO_DO_SUCCESS:
      return { ...state, isChangedTask: true };
    case actions.DELETE_TASK_TO_DO_FAILURE:
      return { ...state, isChangedTask: false };

    case actions.DELETE_TASK:
      return {
        ...state,
        isChangedTask: null,
        isFetchingTasks: true,
      };
    case actions.DELETE_TASK_SUCCESS:
      return { ...state, isChangedTask: true };
    case actions.DELETE_TASK_FAILURE:
      return { ...state, isChangedTask: false, isFetchingTasks: false };

    default:
      return state;
  }
}

export default Reducer;
