const actions = {
  FETCH_TASK_TASKS: "FETCH_TASK_TASKS",
  FETCH_TASK_TASKS_SUCCESS: "FETCH_TASK_TASKS_SUCCESS",
  FETCH_TASK_TASKS_FAILURE: "FETCH_TASK_TASKS_FAILURE",

  FETCH_TASK_SATISTICS: "FETCH_TASK_SATISTICS",
  FETCH_TASK_SATISTICS_SUCCESS: "FETCH_TASK_SATISTICS_SUCCESS",
  FETCH_TASK_SATISTICS_FAILURE: "FETCH_TASK_SATISTICS_FAILURE",

  FETCH_TASK_RESPONSIBLE_EXECUTORS: "FETCH_TASK_RESPONSIBLE_EXECUTORS",
  FETCH_TASK_RESPONSIBLE_EXECUTORS_SUCCESS:
    "FETCH_TASK_RESPONSIBLE_EXECUTORS_SUCCESS",
  FETCH_TASK_RESPONSIBLE_EXECUTORS_FAILURE:
    "FETCH_TASK_RESPONSIBLE_EXECUTORS_FAILURE",

  FETCH_TASK_SATISTICS_BY_DUEDATE: "FETCH_TASK_SATISTICS_BY_DUEDATE",
  FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS:
    "FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS",
  FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE:
    "FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE",

  FETCH_BUILDINGS: "FETCH_BUILDINGS",
  FETCH_BUILDINGS_SUCCESS: "FETCH_BUILDINGS_SUCCESS",
  FETCH_BUILDINGS_FAILURE: "FETCH_BUILDINGS_FAILURE",

  ADD_TASK_TASKS: "ADD_TASK_TASKS",
  ADD_TASK_TASKS_SUCCESS: "ADD_TASK_TASKS_SUCCESS",
  ADD_TASK_TASKS_FAILURE: "ADD_TASK_TASKS_FAILURE",

  UPDATE_TASK_TASKS: "UPDATE_TASK_TASKS",
  UPDATE_TASK_TASKS_SUCCESS: "UPDATE_TASK_TASKS_SUCCESS",
  UPDATE_TASK_TASKS_FAILURE: "UPDATE_TASK_TASKS_FAILURE",

  FETCH_TASK_BY_ID: "FETCH_TASK_BY_ID",
  FETCH_TASK_BY_ID_SUCCESS: "FETCH_TASK_BY_ID_SUCCESS",
  FETCH_TASK_BY_ID_FAILURE: "FETCH_TASK_BY_ID_FAILURE",

  RESET_TASK: "RESET_TASK",

  ADD_TASK_COMMENT: "ADD_TASK_COMMENT",
  ADD_TASK_COMMENT_SUCCESS: "ADD_TASK_COMMENT_SUCCESS",
  ADD_TASK_COMMENT_FAILURE: "ADD_TASK_COMMENT_FAILURE",

  UPDATE_TASK_COMMENT: "UPDATE_TASK_COMMENT",
  UPDATE_TASK_COMMENT_SUCCESS: "UPDATE_TASK_COMMENT_SUCCESS",
  UPDATE_TASK_COMMENT_FAILURE: "UPDATE_TASK_COMMENT_FAILURE",

  DELETE_TASK_COMMENT: "DELETE_TASK_COMMENT",
  DELETE_TASK_COMMENT_SUCCESS: "DELETE_TASK_COMMENT_SUCCESS",
  DELETE_TASK_COMMENT_FAILURE: "DELETE_TASK_COMMENT_FAILURE",

  ADD_TASK_TO_DO: "ADD_TASK_TO_DO",
  ADD_TASK_TO_DO_SUCCESS: "ADD_TASK_TO_DO_SUCCESS",
  ADD_TASK_TO_DO_FAILURE: "ADD_TASK_TO_DO_FAILURE",

  UPDATE_TASK_TO_DO: "UPDATE_TASK_TO_DO",
  UPDATE_TASK_TO_DO_SUCCESS: "UPDATE_TASK_TO_DO_SUCCESS",
  UPDATE_TASK_TO_DO_FAILURE: "UPDATE_TASK_TO_DO_FAILURE",

  DELETE_TASK_TO_DO: "DELETE_TASK_TO_DO",
  DELETE_TASK_TO_DO_SUCCESS: "DELETE_TASK_TO_DO_SUCCESS",
  DELETE_TASK_TO_DO_FAILURE: "DELETE_TASK_TO_DO_FAILURE",

  FETCH_TASK_TEMPLATE_BY_BUILDING_ID: "FETCH_TASK_TEMPLATE_BY_BUILDING_ID",
  FETCH_TASK_TEMPLATE_BY_BUILDING_ID_SUCCESS: "FETCH_TASK_TEMPLATE_BY_BUILDING_ID_SUCCESS",
  FETCH_TASK_TEMPLATE_BY_BUILDING_ID_FAILURE: "FETCH_TASK_TEMPLATE_BY_BUILDING_ID_FAILURE",

  DELETE_TASK: "DELETE_TASK",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",

  UPDATE_TASK_COMMENT_EMOJI: "UPDATE_TASK_COMMENT_EMOJI",

  FETCH_TASK_TASKS_WITH_RELATED_DATA: "FETCH_TASK_TASKS_WITH_RELATED_DATA",
  FETCH_TASK_TASKS_WITH_RELATED_DATA_SUCCESS: "FETCH_TASK_TASKS_WITH_RELATED_DATA_SUCCESS",
  FETCH_TASK_TASKS_WITH_RELATED_DATA_FAILURE: "FETCH_TASK_TASKS_WITH_RELATED_DATA_FAILURE",

  CHANGE_ISUPDATING_STATUS: "CHANGE_ISUPDATING_STATUS",
};

export default actions;
