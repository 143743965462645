import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingChecklistTemplate(action) {
  try {
    const response = action.buildingId
      ? yield callApi(() =>
          getRequest(
            `building/checklist-templates?buildingId=${action.buildingId}`
          )
        )
      : yield callApi(() => getRequest(`building/checklist-templates`));
    yield put({
      type: actions.FETCH_BUILDING_CHECKLIST_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_CHECKLIST_TEMPLATE_FAILURE });
  }
}

function* addBuildingChecklistTemplate(action) {
  try {
    yield callApi(() =>
      postRequest(`building/checklist-templates`, action.payload)
    );
    yield put({
      type: actions.ADD_BUILDING_CHECKLIST_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_CHECKLIST_TEMPLATE_FAILURE });
  }
}

function* editBuildingChecklistTemplate(action) {
  try {
    yield callApi(() =>
      putRequest(`building/checklist-templates?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_CHECKLIST_TEMPLATE_FAILURE });
  }
}

function* deleteBuildingChecklistTemplate(action) {
  try {
    yield callApi(() =>
      deleteRequest(`building/checklist-templates?id=${action.id}`)
    );

    yield put({
      type: actions.DELETE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_CHECKLIST_TEMPLATE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.FETCH_BUILDING_CHECKLIST_TEMPLATE,
      getBuildingChecklistTemplate
    ),
  ]);
  yield all([
    takeLatest(
      actions.ADD_BUILDING_CHECKLIST_TEMPLATE,
      addBuildingChecklistTemplate
    ),
  ]);
  yield all([
    takeLatest(
      actions.UPDATE_BUILDING_CHECKLIST_TEMPLATE,
      editBuildingChecklistTemplate
    ),
  ]);
  yield all([
    takeLatest(
      actions.DELETE_BUILDING_CHECKLIST_TEMPLATE,
      deleteBuildingChecklistTemplate
    ),
  ]);
}
