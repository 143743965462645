const actions = {
  FETCH_BUILDING_CHECKLIST_TEMPLATE: "FETCH_BUILDING_CHECKLIST_TEMPLATE",
  FETCH_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
    "FETCH_BUILDING_CHECKLIST_TEMPLATE_SUCCESS",
  FETCH_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
    "FETCH_BUILDING_CHECKLIST_TEMPLATE_FAILURE",

  ADD_BUILDING_CHECKLIST_TEMPLATE: "ADD_BUILDING_CHECKLIST_TEMPLATE",
  ADD_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
    "ADD_BUILDING_CHECKLIST_TEMPLATE_SUCCESS",
  ADD_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
    "ADD_BUILDING_CHECKLIST_TEMPLATE_FAILURE",

  UPDATE_BUILDING_CHECKLIST_TEMPLATE: "UPDATE_BUILDING_CHECKLIST_TEMPLATE",
  UPDATE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
    "UPDATE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS",
  UPDATE_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
    "UPDATE_BUILDING_CHECKLIST_TEMPLATE_FAILURE",

  DELETE_BUILDING_CHECKLIST_TEMPLATE: "DELETE_BUILDING_CHECKLIST_TEMPLATE",
  DELETE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS:
    "DELETE_BUILDING_CHECKLIST_TEMPLATE_SUCCESS",
  DELETE_BUILDING_CHECKLIST_TEMPLATE_FAILURE:
    "DELETE_BUILDING_CHECKLIST_TEMPLATE_FAILURE",

  RESET_BUILDING_CHECKLIST_TEMPLATE: "RESET_BUILDING_CHECKLIST_TEMPLATE",
};

export default actions;
