import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function BrannIcon({
  icon,
  onClick,
  size = 20,
  color = "gray",
  marginRight,
  ...rest
}) {
  return (
    <FontAwesomeIcon
      icon={icon}
      onClick={onClick}
      style={{ fontSize: size, color: color, marginRight, cursor: "pointer" }}
      {...rest}
    />
  );
}
