const actions = {
  FETCH_USER_GROUP: "FETCH_USER_GROUP",
  FETCH_USER_GROUP_SUCCESS: "FETCH_USER_GROUP_SUCCESS",
  FETCH_USER_GROUP_FAILURE: "FETCH_USER_GROUP_FAILURE",

  CREATE_USER_GROUP: "CREATE_USER_GROUP",
  CREATE_USER_GROUP_SUCCESS: "CREATE_USER_GROUP_SUCCESS",
  CREATE_USER_GROUP_FAILURE: "CREATE_USER_GROUP_FAILURE",

  UPDATE_USER_GROUP: "UPDATE_USER_GROUP",
  UPDATE_USER_GROUP_SUCCESS: "UPDATE_USER_GROUP_SUCCESS",
  UPDATE_USER_GROUP_FAILURE: "UPDATE_USER_GROUP_FAILURE",

  DELETE_USER_GROUP: "DELETE_USER_GROUP",
  DELETE_USER_GROUP_SUCCESS: "DELETE_USER_GROUP_SUCCESS",
  DELETE_USER_GROUP_FAILURE: "DELETE_USER_GROUP_FAILURE",
};

export default actions;
